html{height: 100%;overflow: hidden;}
body{height: 100%;position: relative;}
#root{height: 100%;}
/* table{padding: 5px 10px} */
i{cursor: pointer}

/* General Style that apply sitewide */
    h1, h3, p, a, button{font-family: 'AileronRegular'}
    li{list-style-type: none;}
    ::-webkit-scrollbar{display: none;}
    a{color: inherit}
    a:hover{color: inherit}
    .app{position: relative;height: 100%;width: 100%;background:  url("./images/piriji-home.jpg");background-size: 100% 100%;height: 100%;display: flex;flex-direction: column;align-items: flex-end;justify-content: center;overflow: hidden}
    .button{background-color: black;color: white; width: fit-content; height: fit-content;padding: 10px 25px;border: unset; cursor: pointer;border-radius: 5px;}
    .display-none{display: none}
    .background-info{background-color: rgb(27,27,27);height: calc(100vh - 57px);}
    .black-background{background-color: rgba(27,27,27, 0.95);text-align: center;width: 50%;border-radius: 25px;padding: 30px 20px;}
    p{margin: 0}

    @media only screen and (max-width: 1100px){
        .app{background-size: 110% 100%;}
    }

    @media only screen and (max-width: 1000px){
        .app{background-size: 130% 100%;}
    }

    @media only screen and (max-width: 900px){
        .app{background-size: 145% 100%;}
    }

    @media only screen and (max-width: 700px){
        .app{background-size: 155% 100%;}
    }

    @media only screen and (max-width: 475px){
        .background-info{height: calc(100vh - 90px);}
        .app{background: url('./images/piriji-home-mobile.jpg');background-size: 100% 100%;}
    }
/* end section */

/* Footer */
    .footer{width: 100%;background-color: black;position: absolute;bottom: 0;height: 60px;animation: footer 0.5s}
    .homepage-footer-desktop{position: absolute;width: 50%;top: 19px;display: flex;flex-direction: row;justify-content: space-between;padding: 0 20px;}
    .homepage-footer-mobile{display: none;justify-content: center;padding-top: 20px;}
    .homepage-footer-mobile div{display: flex;flex-direction: row;width: 30%;margin: 0 auto;justify-content: space-between;margin-bottom: 5px}
    .homepage-footer-mobile div a{width: 100%}
    .link p{color: white;}
    .link p:hover{transform: scale(1.1)}
    .display-footer{position: absolute;bottom: 20px;width:100%}

    @keyframes footer{
        0%{height: 0px}
        25%{height: 15px}
        30%{height: 18px}
        50%{height: 30px}
        60%{height: 36px}
        75%{height: 45px}
        85%{height: 51px}
        100%{height: 60px}
    }

    @media only screen and (max-width: 700px){
        .homepage-footer-desktop{display: none;}
        .homepage-footer-mobile{display: block}
        .footer{height: 95px}
    }

    @media only screen and (max-width: 650px){
        .homepage-footer-mobile div{width: 50%}
    }

    @media only screen and (max-width: 475px){
        .footer{background: rgba(27,27,27,0.7)}
    }

    @media only screen and (max-width: 450px){
        .homepage-footer-mobile div{width: 70%}
    }
/* End Footer */

/* Home Page */
    .wrapper{width: 100%;height: 100%;max-height: 100%;overflow: hidden scroll;text-align: center;display: flex;flex-direction: column;align-items: flex-end;justify-content: center;}
    .options{width:45%;text-align: center;margin-right: 45px;}
    .svg-image{width:45%; height:45%;margin-right: 45px;}
    .display-footer:hover{transform: scale(1.1);}
    .display-footer p{color: white;margin-bottom: 0;font-size: 16px;cursor: pointer}
    .display-footer i{color: white;font-size: 25px;cursor: pointer;}
    .homepage-footer{width:100%;padding:10px 10px;margin-top:auto;background-color: black;display:flex;flex-direction: row}
    .homepage-footer .row a{color: white;}
    .form-div{text-align: center;width: fit-content;margin-right: 15%;background-color: rgba(20, 20, 20, 0.9);border-radius: 15px;padding: 30px 50px}
    .form-div h1{font-size: 65px;color: white;font-family: 'AileronLight' !important;margin-bottom: 0px}
    .form{padding: 20px;text-align: center;margin: 0 auto;color: white;}
    .form::-webkit-scrollbar{display: none}
    .form label{font-size: 25px;}
    .form input,.reset-form input{display: block;width: 80%;margin: 0 auto;margin-bottom:30px;border: unset;background: transparent;border-bottom: 1px solid rgb(173, 173, 173);color: white;text-align: center;margin-top: 10px;padding: 5px 0px}
    .form input{width: 100%}
    .form input:last-of-type{margin-bottom:20px !important}
    .form input::-webkit-input-placeholder,.reset-form input::-webkit-input-placeholder{color: white;text-align: center;}
    .form input::-webkit-calendar-picker-indicator{background-color: rgba(255,255,255,.5) !important;}
    .form input::-webkit-datetime-edit{margin-left: 40px}
    .signup{width: 350px}
    .form h1{font-size: 90px;}
    .password-reset{margin-bottom: 20px;}
    .password-reset a{text-decoration: underline;}
    .password-reset a:hover{text-decoration: none;color: blue}
    .logo{font-size: 230px;margin: 0;color: white;overflow: hidden;font-weight: unset;line-height: 125%;}
    .user-options{margin: 0 auto;margin-top: 20px;display: flex;flex-direction: column;align-items: center;justify-content: center;}
    .user-options p:first-of-type{margin-bottom: 20px;}
    .user-options p a{margin-left: 10px;}
    .user-options p:last-of-type a:last-of-type{margin-left: 7px !important}
    .user-options p:last-of-type{text-align: start !important;}

    @media only screen and (max-width: 1600px){
        .form-div{margin-right: 16%}
    }

    @media only screen and (max-width: 1400px){
        .form-div{margin-right: 13%}
        .form-div h1{font-size: 60px;margin-bottom: 0}
    }

    @media only screen and (max-width: 1200px){
        .form-div{margin-right: 10%}
        .form-div h1{font-size: 50px;}
    }

    @media only screen and (max-width: 1100px){
        .form-div{margin-right: 5%}
    }

    @media only screen and (max-width: 1000px){
        .form-div h1{font-size: 40px;}
    }

    @media only screen and (max-width: 800px){
        .wrapper{align-items: center !important;}
        .form-div{margin: 0;}
    }

    @media only screen and (max-width: 475px){
        .form-div{margin: 0;margin-top: 10px;max-height: 65%;padding: 0 20px;overflow: hidden scroll;background-color: unset;}
        .form-div h1{font-size: 30px;margin-bottom: 0}
        .form label{font-size: 16px;}
        .form input{margin-bottom: 20px;width: 100% !important}
        .svg-image{margin-right: 0}
        .options{margin-right: 0;display: flex;flex-direction: row;width: 50%;justify-content: space-between;}
        .wrapper .button{background-color: rgba(27,27,27,0.7);}
    }

    @media only screen and (max-width: 375px) and (max-height: 700px){
        .options{width: 55%;}
    }

    @media only screen and (max-width: 365px){
        .form-div{padding: 10px 20px;margin-top: 40px;max-height: 80%}
        .form{padding-top: 10px;padding-bottom: 0px}
    }

    /* iphone se */
    @media only screen and (max-width: 321px){
        .options{width: 60%}
    }
/* End Homepage */

/* Info Navbar */
    .navbar{display: flex;padding: 5px 20px;background-color: rgb(27,27,27);align-items: center;justify-content: center;}
    .navbar h1{letter-spacing: 2px;color: white;line-height: 100%;margin: 0;position: absolute;left: 20px;}
    .navbar h1 a{font-family: 'AileronLight';}
    .navbar a{color: white}
    .navbar ul{display: flex;flex-direction: row;justify-content: space-between;width: 50%;}
    .navbar li{list-style-type: none;}
    .navbar li:hover{transform: scale(1.1)}

    @media only screen and (max-width: 900px){
        .navbar ul{width: 80%}
    }

    @media only screen and (max-width: 475px){
        .navbar{flex-direction: column;padding-top: 10px}
        .navbar h1{position: relative;left: 0}
        .navbar ul{width: 100%;padding: 0}
    }
/* End Info Navbar */

/* About Page */
    .about{width: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;background-image: url('./images/about.jpg');background-size: 100% 100%;background-repeat: no-repeat;}
    .about h1{color: white;font-size: 80px;font-family: 'AileronLight';letter-spacing: 1px;margin: 0;animation: home-display 1s;}
    .subscript{color: rgb(251,189,128) !important;font-size: 35px !important;margin-top: 10px !important;animation: home-display 1s;}
    .about p{color: white;letter-spacing: 0.5px;width: 70%;margin: 0 auto;margin-top: 50px;text-align: center;line-height: 175%;font-size: 16px;animation: home-display 1s;}
    .about-container{max-height: 100%;overflow: hidden scroll;}

    @keyframes home-display{
        0%{opacity: 0}
        10%{opacity: 0.1}
        20%{opacity: 0.2}
        30%{opacity: 0.3}
        40%{opacity: 0.4}
        50%{opacity: 0.5}
        60%{opacity: 0.6}
        70%{opacity: 0.7}
        80%{opacity: 0.8}
        90%{opacity: 0.9}
        100%{opacity: 1}
    }

    @media only screen and (max-width: 1300px){
        .about h1{font-size: 60px}
        .about-container{width: 60%;}
        .subscript{font-size: 30px !important;letter-spacing: -0.5px;}
        .about-container p{font-size: 16px;width: 70%;text-align: center;margin-top: 20px}
    }

    @media only screen and (max-width: 1024px){
        .privacy-policy{justify-content: center;}
        .about-container{width: 70%;margin-left: 0;}
        .about-container p{text-align: start;}
    }

    @media only screen and (max-width: 745px){
        .about-container{max-height: 80% !important;width: 85%;}
        .about h1{font-size: 45px}
        .subscript{font-size: 20px !important;letter-spacing: -0.5px;}
        .about-container p{font-size: 12px;width: 80%;text-align: center;margin-top: 20px}
    }

    @media only screen and (max-width: 475px){
        .about h1{font-size: 34px}
        .about-container{max-height: 90% !important;width: 85%;}
        .subscript{font-size: 16px !important;letter-spacing: -0.5px;}
        .about-container p{font-size: 14px;width: 100%;text-align: center;margin-top: 30px}
    }
/* End About Page */

/* Privacy Policy */
    .privacy-policy{background-repeat: no-repeat;background-size: 100% 100%;display: flex;align-items: center;}
    .privacy-policy-container{width:40%;text-align: center;margin-left: 30px;overflow: hidden scroll;max-height: 100%}
    .privacy-policy-container h1,.privacy-policy-container h3{color: white;}
    .privacy-policy-container ul{width: fit-content;margin: 0 auto !important}
    .privacy-policy-container p{font-size: 16px;line-height: 175%;letter-spacing: 0.5px;}
    .privacy-policy-container ul li{text-align: start;}
    li, p{color: white;}

    @media only screen and (max-width: 1300px){
        .privacy-policy-container{width: 50%}
    }

    @media only screen and (max-width: 1024px){
        .privacy-policy{justify-content: center;}
        .privacy-policy-container{width: 70%;margin-left: 0;}
        .privacy-policy-container p{text-align: start;}
    }

    @media only screen and (max-width: 745px){
        .privacy-policy-container{max-height: 80% !important;width: 85%;}
    }

    @media only screen and (max-width: 475px){
        .privacy-policy-container p{font-size: 14px}
    }
/* End Privacy Policy */

/* Search Page */
    #map{position: absolute;right: 0;top: 0;width:100%;height: 100%}
    .mapboxgl-canvas{height: 100% !important; width: 100% !important}
    .ui.basic.segment{display: block;position: fixed;padding:0;height: 100% !important;width:100% !important}
    .mapboxgl-ctrl-top-right{right: unset !important; left: 50px; top: 30px !important;width:35%;}
    .mapboxgl-ctrl-geocoder--icon-search{display:none}
    .mapboxgl-ctrl{width:100%;margin: 0 !important;height:30px}
    .mapboxgl-ctrl-geocoder--pin-right{display: none}
    .mapboxgl-ctrl-geocoder--input{width:100%; border-radius: 25px;height: 100%;padding-left: 25px;outline:none;border: unset; border: 1px solid gray; box-shadow: 0 0 5px #888888}
    .mapboxgl-ctrl-group{position:absolute; height:unset; width: max-content; left: unset;right: 0}
    /* Autocomplete Results */
        .suggestions{list-style-type: none;padding-left: 0;background-color: white;margin-top: -20px;margin-left: 1px;width: 99.5%;border: 1px solid gray;border-bottom-left-radius: 15px;border-bottom-right-radius: 15px;}
        .suggestions li{color:black}
        .suggestions li:first-of-type{padding-top: 20px}
        .suggestions li:last-of-type{border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;}
        .suggestions li:last-of-type .mapboxgl-ctrl-geocoder--suggestion{border-bottom: unset}
        .suggestions li:hover{background-color: black;color:white !important; cursor: pointer;}
        .suggestions li a:hover{color: white;}
        .mapboxgl-ctrl-geocoder--suggestion-title{min-width: 120px !important;max-width: 120px !important;}
        .mapboxgl-ctrl-geocoder--suggestion{display: inline-flex;border-bottom: 1px solid gray;width: 100%; padding: 5px}
        .mapboxgl-ctrl-geocoder--suggestion-address{padding-left: 5px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
        .mapboxgl-ctrl-geocoder--input{padding-right:35px}
        .suggestions .active{background-color:black;}
        .suggestions .active a{color:white}
        .searchbar{align-items: center; position: absolute; z-index: 998;left: 59px; top: 35px}
        .searchbar i:hover{cursor: pointer;}
    /* End Autocomplete */

    /* Filters Container */
        .filters{display: none !important;position: absolute;}
        .filters-container{display: none;position: absolute;top: -4px; left: -8px;background-color: black;z-index: 0;border-radius: 25px;height:28px;align-items: center;justify-content: center;}
        .filters-container i{position: absolute;right: 0;display: flex;justify-content: center;padding-bottom: 19.3px;color: white}
        .filters-container ul{list-style: none;display: flex;flex-direction: row;margin: 0 !important;padding-inline-start: 0;}
        .filters-container ul li{display: flex;align-items: center;margin-bottom: 2px;padding: 1px 10px 1px 10px; margin-right: 3px;cursor: pointer;color: white;user-select: none;border-right: 1px solid white}
        #price li{border-right:none !important}
        .filters-container ul li:hover{font-weight: bold}
        .prices-container{display:none;position: absolute;top: 26px}
        #price:hover .prices-container{display: block}
        .prices-container li{justify-content: center !important;background-color: black;color:white;margin: 0 !important;border-bottom: 1px solid white;border-right: none !important}
        .prices-container li:last-child{border-bottom: none;}
        .dress-code-container{display:none;position: absolute;left: 285px;top: 26px}
        .dress-code:hover .dress-code-container{display: block}
        .dress-code-container li{justify-content: center !important;background-color: black;color:white;margin: 0 !important;border-bottom: 1px solid white}
        .dress-code{width: 116px;}
        .dress-code li{border-right: none !important;justify-content: center;}
        .dress-code-container li:last-child{border-bottom: none !important;}
    /* End Filters Container */

    /* Donation Container */
        .tip-jar-container{display: flex;width: 100%;height:100%;background-color: rgba(27,27,27, 0.5);z-index: 999;position: absolute;align-items: center}
        .tip-jar{position: relative;background-color: rgba(27,27,27, 0.8);width: 40%;margin: 0 auto;color: white;border-radius: 25px;}
        .tip-jar i:first-child{position: absolute;right: 5px;top: 10px}
        .tip-jar h1{width:70%;font-size: 20px;margin: 0 auto;line-height: 175%;;text-align: center;padding: 15px 15px;font-weight:400}
        .input-container{text-align: center;}
        .input-container input{border: unset;background: transparent;border-bottom: 1px solid white;color: white;margin-bottom: 20px;text-align: center;}
        .input-container input::-webkit-input-placeholder{text-align: center;}
        .paypal-buttons{width: 75%;margin: 0 auto}
        .payment-complete{position: relative;background-color: rgba(27,27,27, 0.6);border-radius: 25px;width: 60%;height:20%;margin: 0 auto;padding: 15px 15px;display: flex; align-items: center;}
        .payment-complete h3{color: white;text-align: center;}
    /* End Donation Container */
    
    .mapboxgl-popup{max-width: 100% !important;}
    .react-slideshow-container + div.indicators {position: absolute; bottom: 10px; width: 100%;}
    .images-wrap{height:100vh}
    .react-slideshow-container + div.indicators > div.active{background: white !important;}

    /* Sidebar */
        .sidebar-container{display: none;width: 100%;position: absolute;height: 100%;background-color: rgba(0,0,0,0.4);z-index: 999;border-radius: 5px;border-radius: .28571429rem;border: 1px solid rgba(34,36,38,.15);}
        .sidebar-menu{display: block;background: #1b1c1d;width: fit-content;height: 100%;padding-top: 5px;overflow: hidden;-webkit-transform: translateX(-100%);transform: translateX(-100%);transition: transform .7s}
        .sidebar-menu ul{padding: 0 !important;margin: 0 !important}
        .sidebar-menu ul li{display: flex;cursor: pointer;flex-direction: column;align-items: center; justify-content: center;height: 75px;width: 100%;border-bottom: 1px solid rgba(255,255,255,.08);padding: 20px 50px;margin: 0 auto;}
        .sidebar-menu ul li:hover{background-color:rgba(48, 47, 47, 0.5)}
        .sidebar-menu ul li i{font-size: 24px;margin-bottom: 10px}
    /* End Sidebar */
/* End Search Page */


/*Bar Show Page Starts Here*/
    .hours-of-operation{display: flex;flex-direction: row;position: absolute;bottom: 1px; left: 30px; align-items: baseline;}
    .hours-of-operation h1{font-family: 'Roboto Condensed', sans-serif; color: white; font-weight: 550; padding-right: 15px; font-size: 25px;cursor: pointer}
    .menus{position: absolute;bottom: 18px;right: 5px;}
    .menus button{margin-right: 8px;background-color: black; color: white; border: none;cursor: pointer;outline: unset;padding: 2px 2px 2px 2px}
    .pushable{overflow: hidden}
    .happy-hours{position: absolute;left: 8px;bottom: 50px; background-color: rgba(27,27,27, 0.8); width:16%;border-radius: 25px;}
    .happy-hours table{color: white}
    .happy-hours table tbody{width: 90%;display: flex;flex-direction: column;text-align: center;}
    .happy-hours table tbody tr{display: flex; flex-direction: row;}
    .happy-hours table tbody tr th{flex: 1;padding-bottom: 3px;}
    .react-pdf__Document{position: absolute;bottom: 0;height:100%;width:100%;display:flex;align-items: center;justify-content: center;background-color: rgba(27,27,27, 0.9);z-index:1}
    .react-pdf__Page{width: 30%;height:85%}
    .react-pdf__Page__canvas{width:100% !important;height:100% !important}
    .marker{background-image: url('/images/marker.svg');background-repeat: no-repeat;width: 18px;height: 33px;cursor: default;}
    .mapboxgl-popup-content{width: fit-content !important; background-color: rgba(27,27,27,.8) !important;border-radius: 20px !important;z-index: 999;padding: 10px 15px 15px !important}
    .mapboxgl-popup-content h1{font-size: 18px; text-decoration: underline;color: white;text-align: center;}
    .mapboxgl-popup-content h2{font-size: 13px; color: white;text-align: center;margin-top: 5px !important}
    .mapboxgl-popup-content i{position: absolute; top: 5px;right: 5px;font-size:20px;color: white;cursor: pointer}
    .mapboxgl-popup-content i:hover{color: red}
    .mapboxgl-popup-content table{padding: 0px 15px 0px 15px}
    div[aria-label="Map marker"]{display: none !important}
    .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{border-top-color: rgba(27,27,27,.8) !important;z-index: 999;cursor: default;}
    .bar-name{padding: 0px 20px !important; margin-bottom: 0 !important}
    .button-hours{text-align: center;}
    .button-hours button{background-color: black; color: white; border: none;cursor: pointer;outline: unset;padding: 6px 6px 6px 6px}
/* End Bar Show Page */


/* Bar Hours modal on the saved bars section*/
    /* Saved Bars Container */
        .popup-message-container{display: flex;width: 100%;height:100%;background-color: rgba(27,27,27, 0.5);z-index: 999;position: absolute;align-items: center}
        .popup-message{position: relative;background-color: rgba(27,27,27, 0.8);width: 40%;margin: 0 auto;color: white;border-radius: 25px;padding: 15px 15px}
        .popup-message i{position: absolute;right: 10px;top: 5px}
        .popup-message h3{font-size: 18px;line-height: 175%;margin-top: 5px;width:90%;margin: 0 auto;padding:15px 15px;font-weight: 400;}
        .hidden{bottom: 40px !important}
        .speech-bubble{display: none;position: absolute;background: black;color: white;border-radius: .4em;padding: 5px;bottom: 195px;left: 151px}
        .show-bars{bottom: 85px !important;left: 148px !important}
        .speech-bubble:after{content: '';position: absolute;bottom: 0;left: 50%;width: 0;height: 0;border: 7px solid transparent;border-top-color: black;border-bottom: 0;margin-left: -7px;margin-bottom: -7px;}
    /* Saved Bars Container */
    .saved-bars-container{display: flex;position: absolute; bottom: 35px; left: 50px;max-width: 90%;padding-bottom: 4px;}
    .saved-bars-container::-webkit-scrollbar-track{background-color: rgba(54, 53, 50,0);-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);width: 4px !important;border-radius: 25px;}
    .saved-bars-container::-webkit-scrollbar-thumb{background: #21ba45;}
    .saved-bars-container::-webkit-scrollbar-thumb:hover{background: #21ba45;}
    .saved-bars-header{display: inline-flex;align-items: center;position: absolute; left:50px; bottom: 150px;color: white}
    .saved-bars-header h1{padding-right: '5px'; margin-bottom: '0px'}
    .saved-bars-header i{cursor: pointer;margin-left: 10px;margin-bottom: 11px;color: black}
    .saved-bars-header i:last-child{display: none}
    .saved-bar-card{flex: 0 0 200px;height: 120px;padding: 10px;background-color: rgba(27,27,27, 0.8); border-radius: 10px; border: 1px;margin-right: 15px;box-shadow: 0 0 2px #888888}
    .saved-bar-card h1{margin-bottom: 8px;font-size: 15px;margin-bottom: 7px;color: white}
    .saved-bar-card i{cursor: pointer;position: absolute;top: 5px; right: -2px;font-size: 18px; color: red}
    .bar-card{text-align: center; padding-bottom: 5px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;height: 100%;}
    .bar-card div:first-child{position: relative}
    .bar-card div:first-child a{font-size: 15px; color: white}
    .bar-card div:first-child p{color: white}
    .bar-card div:last-child{display: flex; flex-direction: row}
    .bar-card div:last-child button:first-child{font-size: 13px; padding: 5px;margin-right: 5px; color: white;background-color: black;border: none}
    .bar-card div:last-child button:last-child{font-size: 13px; padding: 5px; color: white;background-color: black;border: none}
    .hours-modal{padding-top: 5px;}
    .hours-modal div{display: flex; justify-content: center;position: relative}
    .hours-modal div i{position: absolute;top: 0;right: 0;color: white;font-size: 15px; cursor: pointer}
/* End Modal */


/* Reset Password Page */
    .reset-form{color: white;width: 100%;height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;}
    .reset-form input{width: 250px}
    .reset-form button{height: 40px;width: 200px;background: black;color: white;border: none;cursor: pointer;}
/* End Reset Password Page */


@media only screen and (max-width: 1024px){
    /*Home Page*/
    .svg-image{height:35%;width:95%}
    .logo{font-size: 200px;line-height:200px}

    /* Search Pagge */
    .mapboxgl-ctrl-top-right{left: 17px;width: 50% !important}
    .searchbar{left: 25px}
    .saved-bars-header{left: 10px;bottom: 150px}
    .saved-bars-container{left: 10px;bottom: 27px}
    .speech-bubble{left: 102px !important}
    .show-bars{left: 99px !important}
    .tip-jar{width: 60%}
}

@media only screen and (max-width: 750px){
    .logo{font-size: 150px;}
}

@media only screen and (max-width: 637px){
    .tip-jar h1{font-size: 18px}
    
}

@media only screen and (max-width: 550px){
    .logo{font-size: 130px;}
}

@media only screen and (max-width: 500px){
    .logo{font-size: 90px;margin-top: auto !important;}
    .mapboxgl-ctrl-top-right{width: 90% !important}
    .saved-bar-card{flex: 0 0 200px}
    .speech-bubble{bottom: 180px;left: 102px}
    .show-bars{bottom: 45px !important;left: 99.7px !important}
    .hidden{bottom: 15px !important}
    .tip-jar{width: 80%}
    .tip-jar h1{width: 92%;font-size: 16px;text-align: start;}
    .popup-message{width:90%;}
    .popup-message h3{font-size: 14px;line-height: 175%;margin-top: 5px}
    .mapboxgl-popup-content h1{font-size: 15px}
    .mapboxgl-popup-content h2{font-size: 12px}
    .signup-button{position: relative;margin-top: auto;right: 0}
    .signup-button p{font-size: 20px;margin-top: 20px}
    .form-div-mobile{display: block}
    .form-div-mobile{display: flex;flex-direction: column;}
    .login-form-div-mobile{display: block !important;}
    .login-form-div-mobile form{display: flex;flex-direction: column;}
    .login-form-div-mobile .form input{margin-bottom: 20px;margin-top: 10px !important;}
}

@media only screen and (max-width: 420px){
    .signup-button p{flex-direction: column;}
}

@media only screen and (max-width: 400px){
    .tip-jar h1{width: 90%;font-size: 14px;text-align: start;}
    .form {width: 90%;}
    .homepage-footer .row{width:100%;display:flex; flex-direction: row;justify-content: space-between;}
    .homepage-footer .row .link{font-size: 20px;padding:10px;width: 50%;text-align: center;}
    .homepage-footer .row .link a{text-align: center;}
    .footer-header{text-align: center;text-decoration: underline;}
}